@import 'src/styles/media';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  position: relative;
  font-family: $poppinsFontStyle;
  font-size: 16px;
  min-height: 48px;
  line-height: 24px;
  text-align: center;
  border: 0;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out background-color, color;
}

.isLoading {
  &:disabled {
    background: $primaryColor;

    &:hover {
      background: $primaryColor;
    }
  }
}

.primary {
  color: $white;
  background-color: $primaryColor;
  width: 100%;
  text-transform: uppercase;
  font-weight: $boldFontWeight;

  &:hover {
    background: $black80;
  }

  &:disabled {
    opacity: 0.6;
    background: $black10;
    color: $black40;
    cursor: not-allowed;

    &:hover {
      background: $black10;
    }
  }
}

.secondary {
  color: $primaryColor;
  background: transparent;
  font-weight: $semiBoldFontWeight;
  transition: 0.3s ease-in-out color;
  text-transform: uppercase;

  &:hover {
    color: $black80;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.inverted {
  color: $primaryColor;
  background-color: $irisLight;
  width: 100%;
  text-transform: uppercase;
  font-weight: $boldFontWeight;

  border: 1px solid $primaryColor;

  &:hover {
    background: $black20;
  }

  &.blue,
  &.dark,
  &.purple {
    background-color: transparent !important;
    border-color: $white;
    color: $white !important;
  }
}

.inputButton {
  font-size: 12px;
  line-height: 20px;
  color: $blue;
  background: transparent;
  font-weight: $regularFontWeight;
  margin-top: 2px;
}

.optionButton {
  background-color: $white;
  border: 1px solid $black10;
  box-shadow: $boxShadow;
  width: 100%;
  font-weight: $boldFontWeight;

  &:hover {
    background-color: $primaryColor;
    color: $white;
  }
}
